<template>
  <v-container>
    <v-card class="mb-5">
      <v-card-title class="text-h5 font-weight-regular blue-grey white--text">
        Products
      </v-card-title>
    </v-card>

    <v-col cols="12" class="pa-0">
      <router-view />
      <v-card class="px-16">
        <v-card-text class="pt-5">
          <v-autocomplete
            v-model="model"
            :items="items"
            :search-input.sync="search"
            item-text="Description"
            item-value="API"
            label="Start typing to Search"
            placeholder="Start typing to Search"
            prepend-icon="mdi-magnify"
            return-object
            clearable
          />
        </v-card-text>
      </v-card>
    </v-col>

    <v-row v-if="getContent">
      <v-col
        v-for="(item, index) in getContent"
        :key="index"
        xs="12"
        sm="4"
        md="3"
      >
        <v-card max-width="400">
          <v-img
            class="white--text align-end"
            height="200px"
            :src="`${baseUrl}/thumbnails/${item.product.file.thumbnail}`"
          >
            <v-card-title>{{ item.name }}</v-card-title>
          </v-img>

          <v-card-subtitle class="pb-0">
            last update: {{ item.updatedAt }}
          </v-card-subtitle>

          <v-card-text class="text--primary">
            <div>{{ item.name }}</div>
          </v-card-text>

          <v-card-actions>
            <v-btn
              :to="`/pages/products/${item.product.slug}/${item.productId}`"
              color="orange"
              text
            >
              Edit
            </v-btn>

            <v-btn
              class="ml-2 my-2"
              :href="`https://zgcc-eu.com/${getLanguage.lng}/p/${item.product.slug}`"
              target="_blank"
              color="green"
              outlined
              rounded
              small
            >
              preview
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from "@/config";

export default {
  data: () => ({
    content: [],
    search: null,
    model: {}
  }),

  watch: {
    search(val) {}
  },

  computed: {
    ...mapGetters({
      getLanguage: "auth/defaultLanguage"
    }),

    baseUrl() {
      return config.mediaBaseUrl;
    },

    fields() {
      if (!this.model) return [];

      return this.model.map(item, index => {
        return {
          key: index,
          value: item || "n/a"
        };
      });
    },

    items() {
      return this.content.map(entry => {
        const Description =
          entry.name.length > 30 ? entry.name.slice(0, 30) + "..." : entry.name;

        return Object.assign({}, entry, { Description });
      });
    },

    getContent() {
      if (!!this.model && Object.keys(this.model).length > 0) {
        return [this.model];
      }

      return this.content;
    }
  },

  methods: {
    ...mapActions({
      getAllProducts: "products/getProducts"
    }),

    async loadData() {
      const products = await this.getAllProducts({
        order: "desc",
        perPage: 100,
        page: 1,
        lng: this.getLanguage.lng
      });

      this.content = products.data;
    }
  },

  mounted() {
    this.loadData();
  }
};
</script>
